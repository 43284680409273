import {store, authActions, talentActions} from '../_store'
import axios from 'axios'
function authToken() {
    return store.getState().auth.token;
}

function authHeader(url) {
    const token = authToken();
    const isLoggedIn = !!token;
    const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
    if(isLoggedIn && isApiUrl){
        return {'Authorization':`Bearer ${token}`}
    }
    else{
        return {}
    }
}

function handleResponse(response){
    let data = response.data;
    if(response.statusText!='OK'){
        let checkError  = ``;
        if ([401, 403].includes(response.status)) {
            const logout = () => store.dispatch(authActions.logout());
            logout();
        }
        if(data && data.errors && Object.keys(data.errors).length>=1){
            for (let errorList in data.errors){
                let checkErrors = data.errors[errorList]
                let checkLength = 0;
                while(checkLength < checkErrors.length){
                    checkError+=`${(checkErrors[checkLength])} \n`
                    checkLength++;
                }
            }
        }
        const error = (checkError) ?  checkError : data.message;
        return Promise.reject(error);
    }
    return data;
}

function progressEventCheck(progressData){
    if(progressData.progress){
        store.dispatch(talentActions.fileuploadProgress(progressData.progress * 100))
    }
}

function request(method, type){
    return (url, body, requestType=false) => {
        let requestOptions = {
            method,
            headers : authHeader(url),
        }
        if(type=='fileupload'){
            requestOptions['onUploadProgress'] = progressEventCheck
        }
        requestOptions.headers['Accept'] = 'application/json';
        if(body){
            if(requestType==='formData'){
                requestOptions.headers['Content-Type'] = 'multipart/form-data';
                requestOptions.data = body;
            }
            else{
                requestOptions.headers['Content-Type'] = 'application/json';
                requestOptions.data = JSON.stringify(body);
            }
           
        }
        return axios(url, requestOptions).then(handleResponse).catch((error)=>handleResponse(error.response));
    }
}

export const fetchAPIWapper = {
    get:request('GET'),
    post:request('POST'),
    put:request('PUT'),
    delete:request('DELETE'),
    fileupload:request('POST', 'fileupload')
}