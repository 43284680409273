import './App.css';
import RouterComponent from './router'
import Toast from './_components/Toast'
function App() {
  return (
    <div className="App">
      <RouterComponent/>
      <Toast />
    </div>
  );
}

export default App;
