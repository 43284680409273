import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAPIWapper} from '../_helpers'
const name = 'articledetails'
function createInitialState(){
    return {
        articelPage : [],
        articelDetails : {},
        contactData : [],
        faqData : [],
        error : null,
        loader : false
    }
}
function createReducers(){
    function setWishlist(state, {payload}){
        state.wishlist = payload
    }
    function fileuploadProgress(state, {payload}){
        state.fileprogress = payload
    }
    return {
        setWishlist,
        fileuploadProgress
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    function articelPage(){
        return createAsyncThunk(
            `${name}/articelPage`,
             async () => await fetchAPIWapper.get(`${baseUrl}/content-pages`)
        )
    }

    function articelDetails(){
        return createAsyncThunk(
            `${name}/articelDetails`,
             async (data) => await fetchAPIWapper.get(`${baseUrl}/content-details?slug=${data.slug}`)
        )
    }

    function faqData(){
        return createAsyncThunk(
            `${name}/faqData`,
             async () =>  await fetchAPIWapper.get(`${baseUrl}/faq`)
        )
    }

    function contactData(){
        return createAsyncThunk(
            `${name}/create-contact`,
             async (data) =>  await fetchAPIWapper.post(`${baseUrl}/create-contact`,data)
        )
    }

   
    return {
        articelPage: articelPage(),
        articelDetails : articelDetails(),
        faqData : faqData(),
        contactData : contactData()
    }
}

function createExtraReducers(){
    function newcategories(){
        let { pending, fulfilled, rejected } = extraActions.articelPage;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loader = true
                state.articelPage = [];
                state.totalCount = 0;
            },
            [fulfilled]:(state, action)=>{
                state.articelPage = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [rejected]:(state, action) =>{
                state.articelPage = [];
                state.error = action.error;
                state.loader = false
            },

            

            [extraActions.articelDetails.pending]: (state) => {
                state.error = null;
                state.loader = true;
                state.articelDetails = []
            },
            [extraActions.articelDetails.fulfilled]:(state, action)=>{
                state.error = null;
                state.articelDetails = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [extraActions.articelDetails.rejected]:(state, action) =>{
                state.error = action.error;
                state.articelDetails = [];
                state.loader = false
            },


            [extraActions.faqData.pending]: (state) => {
                state.error = null;
                state.faqData = [];
                state.loader = true
            },
            [extraActions.faqData.fulfilled]:(state, action)=>{
                state.error = null;
                state.faqData = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.faqData.rejected]:(state, action) =>{
                state.error = action.error;
                state.faqData = [];
                state.loader = false
            },



            [extraActions.contactData.pending]: (state) => {
                state.error = null;
                state.contactData = [];
                state.loader = true;
            },
            [extraActions.contactData.fulfilled]:(state, action)=>{
                state.error = null;
                state.contactData = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.contactData.rejected]:(state, action) =>{
                state.error = action.error;
                state.contactData = [];
                state.loader = false
            }
        }
    }
    return {
        ...newcategories()
    }
}

const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers,extraReducers  });
export const articelActions = { ...slice.actions, ...extraActions };
export const articleReducer = slice.reducer;