import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux'
import {toastActions} from '../_store/toast.slice'
import 'react-toastify/dist/ReactToastify.css';
export default function Toast(){
    const dispatch = useDispatch(); 
    const toastMessage = useSelector(list=>list.toast.toastMessage)
    const notify = (message) => toast(message,{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    useEffect(()=>{
        if(toastMessage){
            notify(toastMessage);
            setTimeout(()=>{
                dispatch(toastActions.toggleToast({
                  status:'success',
                  message:''
              }))
            },3000)
        }
    },[dispatch, toastMessage])
    return (
      <div>
        <ToastContainer />
      </div>
    );
}