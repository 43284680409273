import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAPIWapper} from '../_helpers'
const name = 'moviedetails'
function createInitialState(){
    return {
        movieslist : [],
        newMoveieList : [],
        trendingMovieList : [],
        topMovieList : [],
        searchMovies : [],
        movieDetails : {},
        movieDetailsPopup : false,
        moviePaymentPopup : {},
        checkPopup : false,
        viewTralor : false,
        viewTralorData : {},
        loaderPayment : false,
        popupDisplayDatas:{
            size : 'lg',
            className :'movieinfo-modal'
        },
        imageUrl : '',
        error : null,
        loader : false,
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    function moveieList(){
        return createAsyncThunk(
            `${name}/movies`,
             async () => await fetchAPIWapper.get(`${baseUrl}/movielist`)
        )
    }
    function movieDetails(){
        return createAsyncThunk(
            `${name}/movieDetails`,
            async ({movieId, auth}) => {
                if(auth){
                    return await fetchAPIWapper.get(`${baseUrl}/v1/moviedetails?id=${movieId}`)
                }
                else{
                    return await fetchAPIWapper.get(`${baseUrl}/moviedetails?id=${movieId}`)
                }
            }
        )
    }
    function newMoveieList(){
        return createAsyncThunk(
            `${name}/newMoveieList`,
             async () => await fetchAPIWapper.get(`${baseUrl}/newmovies`)
        )
    }
    function trendingMovieList(){
        return createAsyncThunk(
            `${name}/trendingMovieList`,
             async () => await fetchAPIWapper.get(`${baseUrl}/trendingmovies`)
        )
    }
    function topMovieList(){
        return createAsyncThunk(
            `${name}/topMovieList`,
             async () => await fetchAPIWapper.get(`${baseUrl}/topmovies`)
        )
    }

    function searchMovies(){
        return createAsyncThunk(
            `${name}/searchMovies`,
             async (data) => await fetchAPIWapper.post(`${baseUrl}/search-movies`, data)
        )
    }
    
    return {
        moveielist: moveieList(),
        movieDetails : movieDetails(),
        newMoveieList: newMoveieList(),
        trendingMovieList : trendingMovieList(),
        topMovieList : topMovieList(),
        searchMovies : searchMovies()
    }
}

function createReducers(){
    function togglePopup(state, {payload}){
        if(payload){
            state.movieDetailsPopup  = payload
            state.checkPopup = true
            state.moviePaymentPopup = {}
            state.popupDisplayDatas = {
                size : 'lg',
                className :'movieinfo-modal'
            }
        }
        else{
            state.movieDetailsPopup  = false
            state.checkPopup = false
        }
    }
    function toggleTrailorPopup(state,{payload}){
        if(payload){
            state.viewTralorData = payload
            state.viewTralor = true
        }
        else{
            state.viewTralorData = {}
            state.viewTralor = false
        }
        
    }
    function toggleLoader(state,{payload}){
        state.loaderPayment = payload
    }
    function togglePaymentPopup(state, {payload}){
        if(payload && Object.keys(payload).length>=0){
            state.moviePaymentPopup = payload
            state.checkPopup = true
            state.movieDetailsPopup = false;
            state.popupDisplayDatas = {
                size : 'md',
                className :'proceed-payment-modal'
            }
        }
        else{
            state.moviePaymentPopup = {}
            state.checkPopup = false
        }
    }
    return {
        togglePopup,
        togglePaymentPopup,
        toggleLoader,
        toggleTrailorPopup
    }
}

function createExtraReducers(){
    function moveielist(){
        let { pending, fulfilled, rejected } = extraActions.moveielist;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [fulfilled]:(state, action)=>{
                state.imageUrl =  (action.payload && action.payload.image_url) ? action.payload.image_url : '';
                state.movieslist = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },
            [extraActions.movieDetails.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.movieDetails.fulfilled]:(state, action)=>{
                state.imageUrl =  (action.payload && action.payload.image_url) ? action.payload.image_url : '';
                state.movieDetails = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.movieDetails.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },
            [extraActions.newMoveieList.pending]: (state) => {
                state.newMoveieList = []
                state.error = null;
                state.loader = true
            },
            [extraActions.newMoveieList.fulfilled]:(state, action)=>{
                state.imageUrl =  (action.payload && action.payload.image_url) ? action.payload.image_url : '';
                state.newMoveieList = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.newMoveieList.rejected]:(state, action) =>{
                state.error = action.error;
                state.newMoveieList = []
                state.loader = false
            },
            [extraActions.trendingMovieList.pending]: (state) => {
                state.trendingMovieList = []
                state.error = null;
                state.loader = true
            },
            [extraActions.trendingMovieList.fulfilled]:(state, action)=>{
                state.imageUrl =  (action.payload && action.payload.image_url) ? action.payload.image_url : '';
                state.trendingMovieList = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.trendingMovieList.rejected]:(state, action) =>{
                state.trendingMovieList = []
                state.error = action.error;
                state.loader = false
            },

            [extraActions.topMovieList.pending]: (state) => {
                state.topMovieList = []
                state.error = null;
                state.loader = true
            },
            [extraActions.topMovieList.fulfilled]:(state, action)=>{
                state.imageUrl =  (action.payload && action.payload.image_url) ? action.payload.image_url : '';
                state.topMovieList = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.topMovieList.rejected]:(state, action) =>{
                state.topMovieList = []
                state.error = action.error;
                state.loader = false
            },


            [extraActions.searchMovies.pending]: (state) => {
                state.searchMovies = []
                state.error = null;
                state.loader = true
            },
            [extraActions.searchMovies.fulfilled]:(state, action)=>{
                state.imageUrl =  (action.payload && action.payload.image_url) ? action.payload.image_url : '';
                state.searchMovies = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.searchMovies.rejected]:(state, action) =>{
                state.searchMovies = []
                state.error = action.error;
                state.loader = false
            }
        }
    }
    return {
        ...moveielist()
    }
}

const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
export const movieActions = { ...slice.actions, ...extraActions };
export const movieReducer = slice.reducer;