import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAPIWapper} from '../_helpers'
const name = 'userdetails'
function createInitialState(){
    return {
        user : {},
        fileprogress : 0,
        wishlist:[],
        userWishlist:[],
        prodWishlist:[],
        catergory_list:[],
        userTalent:[],
        myTalent : [],
        free_talent_list:[],
        removeTalent : [],
        advertisement : {},
        error : null,
        loader : false
    }
}
function createReducers(){
    function setWishlist(state, {payload}){
        state.wishlist = payload
    }
    function fileuploadProgress(state, {payload}){
        state.fileprogress = payload
    }
    return {
        setWishlist,
        fileuploadProgress
    }
}
function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    function talentcategories(){
        return createAsyncThunk(
            `${name}/talentcategories`,
             async () => await fetchAPIWapper.get(`${baseUrl}/talentcategories`)
        )
    }

    
    function usertalentcategories(){
        return createAsyncThunk(
            `${name}/usertalentcategories`,
             async (data) =>  await fetchAPIWapper.post(`${baseUrl}/v1/usertalentcategories`, data)
        )
    }
    function usertalent(){
        return createAsyncThunk(
            `${name}/usertalent`,
             async () =>  await fetchAPIWapper.get(`${baseUrl}/v1/usertalentcategories`)
        )
    }
    function createusertalent(){
        return createAsyncThunk(
            `${name}/createusertalent`,
             async (data) =>  await fetchAPIWapper.post(`${baseUrl}/v1/usertalent`, data, 'formData')
        )
    }
    function uploadTalentFile(){
        return createAsyncThunk(
            `${name}/uploadTalentFile`,
             async (data) =>  await fetchAPIWapper.fileupload(`${baseUrl}/v1/usertalentfiles`, data, 'formData')
        )
    }
    function latesttalent(){
        return createAsyncThunk(
            `${name}/latesttalent`,
             async () => await fetchAPIWapper.get(`${baseUrl}/latesttalent`)
        )
    }
    function removeTalentWork(){
        return createAsyncThunk(
            `${name}/removeTalentWork`,
             async (data) => await fetchAPIWapper.get(`${baseUrl}/v1/removetalentwork?targetwork=${data.targetwork}`)
        )
    }
    function latesttalentbycategory(){
        return createAsyncThunk(
            `${name}/latesttalent`,
            async (categoryId) => {
                return await fetchAPIWapper.get(`${baseUrl}/latesttalent?categoryName=${categoryId.name}`)
            }
        )
    }

    function mytalent(){
        return createAsyncThunk(
            `${name}/mytalent`,
            async (data) => {
                if(data && data.profileId){
                    let searchPrams = new URLSearchParams(data).toString();
                    console.log('searchPrams', searchPrams)
                    return await fetchAPIWapper.get(`${baseUrl}/v1/usertalents?${searchPrams}`)
                }
                else{
                    return await fetchAPIWapper.get(`${baseUrl}/v1/usertalents`)
                }
            }
        )
    }
    function createwishlist(){
        return createAsyncThunk(
            `${name}/createwishlist`,
             async (data) =>  await fetchAPIWapper.post(`${baseUrl}/v1/addtowishlist`, data)
        )
    }

    function createprodwishlist(){
        return createAsyncThunk(
            `${name}/createprodwishlist`,
             async (data) =>  await fetchAPIWapper.post(`${baseUrl}/v1/addtoprodwishlist`, data)
        )
    }

    
    function getwishlist(){
        return createAsyncThunk(
            `${name}/getwishlist`,
             async () =>  await fetchAPIWapper.get(`${baseUrl}/v1/getwishlist`)
        )
    }

    function getAds(){
        return createAsyncThunk(
            `${name}/advertisement`,
             async () =>  await fetchAPIWapper.get(`${baseUrl}/advertisement`)
        )
    }


    function getwishlistdata(){
        return createAsyncThunk(
            `${name}/getwishlistdata`,
             async () =>  await fetchAPIWapper.get(`${baseUrl}/v1/getuserwishlist`)
        )
    }
    function getproducerwishlistdata(){
        return createAsyncThunk(
            `${name}/getwishlistdata`,
             async () =>  await fetchAPIWapper.get(`${baseUrl}/v1/getproducerwishlist`)
        )
    }

    
    return {
        talentcategories: talentcategories(),
        latesttalent : latesttalent(),
        latesttalentbycategory : latesttalentbycategory(),
        usertalent : usertalent(),
        createusertalent : createusertalent(),
        uploadTalentFile : uploadTalentFile(),
        usertalentcategories : usertalentcategories(),
        mytalent : mytalent(),
        createwishlist : createwishlist(),
        createprodwishlist : createprodwishlist(),
        getwishlist : getwishlist(),
        getwishlistdata : getwishlistdata(),
        getproducerwishlistdata : getproducerwishlistdata(),
        removeTalentWork : removeTalentWork(),
        advertisement : getAds()
    }
}

function createExtraReducers(){
    function talentcategories(){
        let { pending, fulfilled, rejected } = extraActions.talentcategories;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loader = true
                state.catergory_list = [];
            },
            [fulfilled]:(state, action)=>{
                state.catergory_list = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [rejected]:(state, action) =>{
                state.catergory_list = [];
                state.error = action.error;
                state.loader = false
            },
            [extraActions.latesttalent.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.latesttalent.fulfilled]:(state, action)=>{
                state.error = null;
                state.free_talent_list = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.latesttalent.rejected]:(state, action) =>{
                state.error = action.error;
                state.free_talent_list = [];
                state.loader = false
            },

            [extraActions.latesttalentbycategory.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.latesttalentbycategory.fulfilled]:(state, action)=>{
                state.error = null;
                state.free_talent_list = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.latesttalentbycategory.rejected]:(state, action) =>{
                state.error = action.error;
                state.free_talent_list = [];
                state.loader = false
            },

            [extraActions.usertalent.pending]: (state) => {
                state.error = null;
                state.loader = true
                state.userTalent = []
                
            },
            [extraActions.usertalent.fulfilled]:(state, action)=>{
                state.error = null;
                state.userTalent = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.usertalent.rejected]:(state, action) =>{
                state.error = action.error;
                state.userTalent = [];
                state.loader = false
            },

            [extraActions.createusertalent.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.createusertalent.fulfilled]:(state, action)=>{
                state.error = null;
                state.loader = false
            },
            [extraActions.createusertalent.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },   
            
            [extraActions.usertalentcategories.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.usertalentcategories.fulfilled]:(state, action)=>{
                state.error = null;
                state.loader = false
            },
            [extraActions.usertalentcategories.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },   
            
            [extraActions.mytalent.pending]: (state) => {
                state.error = null;
                state.myTalent = [];
                state.loader = true
            },
            [extraActions.mytalent.fulfilled]:(state, action)=>{
                state.error = null;
                state.myTalent = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.mytalent.rejected]:(state, action) =>{
                state.error = action.error;
                state.myTalent = [];
                state.loader = false
            }, 

            [extraActions.createwishlist.pending]: (state) => {
                state.error = null;
                state.wishlist = [];
                state.loader = true
            },
            [extraActions.createwishlist.fulfilled]:(state, action)=>{
                state.error = null;
                state.wishlist = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [extraActions.createwishlist.rejected]:(state, action) =>{
                state.error = action.error;
                state.wishlist = [];
                state.loader = false
            }, 

            [extraActions.getwishlist.pending]: (state) => {
                state.error = null;
                state.wishlist = [];
                state.loader = true
            },
            [extraActions.getwishlist.fulfilled]:(state, action)=>{
                state.error = null;
                state.wishlist = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [extraActions.getwishlist.rejected]:(state, action) =>{
                state.error = action.error;
                state.wishlist = [];
                state.loader = false
            }, 

            [extraActions.getwishlistdata.pending]: (state) => {
                state.error = null;
                state.wishlist = [];
                state.loader = true
            },
            [extraActions.getwishlistdata.fulfilled]:(state, action)=>{
                state.error = null;
                state.userWishlist = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [extraActions.getwishlistdata.rejected]:(state, action) =>{
                state.error = action.error;
                state.wishlist = [];
                state.loader = false
            }, 
            [extraActions.createprodwishlist.pending]: (state) => {
                state.error = null;
                state.prodWishlist = [];
                state.loader = true
            },
            [extraActions.createprodwishlist.fulfilled]:(state, action)=>{
                state.error = null;
                state.prodWishlist = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [extraActions.createprodwishlist.rejected]:(state, action) =>{
                state.error = action.error;
                state.prodWishlist = [];
                state.loader = false
            }, 

            [extraActions.removeTalentWork.pending]: (state) => {
                state.error = null;
                state.removeTalent = [];
                state.loader = true
            },
            [extraActions.removeTalentWork.fulfilled]:(state, action)=>{
                state.error = null;
                state.removeTalent = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [extraActions.removeTalentWork.rejected]:(state, action) =>{
                state.error = action.error;
                state.removeTalent = [];
                state.loader = false
            },

            [extraActions.advertisement.pending]: (state) => {
                state.error = null;
                state.advertisement = {};
                state.loader = true
            },
            [extraActions.advertisement.fulfilled]:(state, action)=>{
                state.error = null;
                state.advertisement = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [extraActions.advertisement.rejected]:(state, action) =>{
                state.error = action.error;
                state.advertisement = {};
                state.loader = false
            }

            
        }
    }
    return {
        ...talentcategories()
    }
}

const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
export const talentActions = { ...slice.actions, ...extraActions };
export const talentReducer = slice.reducer;