import {lazy, Suspense} from 'react';
import {Routes, Route, HashRouter} from 'react-router-dom'
const Home = lazy(()=>import('./pages/Home'));
const Layout = lazy(()=>import('./pages/Layouts/Layout'));
const LayoutMovies = lazy(()=>import('./pages/Layouts/LayoutMovies'));
const LayoutReviews = lazy(()=>import('./pages/Layouts/LayoutReviews'));
const TalentLayout = lazy(()=>import('./pages/Layouts/TalentLayout'));
const LayoutTalentProfile = lazy(()=>import('./pages/Layouts/LayoutTalentProfile'));
const LayoutReviewProfile = lazy(()=>import('./pages/Layouts/LayoutReviewProfile'));
const PackageList = lazy(()=>import('./pages/Packages/PackageListHolder'));
const Auth = lazy(()=>import('./pages/Layouts/Auth'));
const Movies = lazy(()=>import('./pages/Movies'));
const PageNotFound = lazy(()=>import('./pages/PageNotFound'));
const TalentHome = lazy(()=>import('./pages/Talent/TalentHome'));
const TalentCreate = lazy(()=>import('./pages/Talent/TalentCreate'));
const TalentCategory = lazy(()=>import('./pages/Talent/TalentCategory'));
const TalentProfile = lazy(()=>import('./pages/Talent/TalentProfile'));
const UpdatePofile = lazy(()=>import('./pages/Talent/UpdatePofile'));
const UpdateTransaction = lazy(()=>import('./pages/Talent/UpdateTransaction'));
const TalentProfileHolder = lazy(()=>import('./pages/Talent/TalentProfileHolder'));
const TalentGalary = lazy(()=>import('./_components/Talent/TalentGalary'));
const TalentWishlistUI = lazy(()=>import('./_components/Talent/TalentWishlistUI'));
const ComingSoon = lazy(()=>import('./pages/ComingSoon'));


const Profile = lazy(()=>import('./_components/Profile'));
const Signin = lazy(()=>import('./_components/Signin'));
const Signup = lazy(()=>import('./_components/Signup'));
const ForgotPassword = lazy(()=>import('./_components/ForgotPassword'));
const Otp = lazy(()=>import('./_components/Users/Otp'));
const Loader = lazy(()=>import('./_components/Loader'));
const MoviePlayer = lazy(()=>import('./pages/MoviePlayer'));
const News = lazy(()=>import('./pages/News/News'));
const NewsDetails = lazy(()=>import('./pages/News/NewsDetails'));
const ReviewsPage = lazy(()=>import('./pages/Reviews/ReviewsPage'));
const VerifyAccount = lazy(()=>import('./pages/Reviews/VerifyAccount'));
const ReveiewsPosted = lazy(()=>import('./pages/Reviews/ReveiewsPosted'));
const PostReviews = lazy(()=>import('./pages/Reviews/PostReviews'));
const ReviewsDetailsPage = lazy(()=>import('./_components/Reviews/ReviewsDetailsPage'));
const CmsPages = lazy(()=>import('./pages/CMS/CmsPages'));


const RouterComponent = () =>{
    return <HashRouter>
            <Routes>
                <Route path="/"  element={<Suspense fallback={<Loader />}><Layout /></Suspense>}>
                    <Route  index element={ <Suspense fallback={<Loader />}> <Home /> </Suspense>}/>
                    <Route  path="/movies" element={ <Suspense fallback={<Loader />}> <Movies pagename="home"/> </Suspense>}/>
                    <Route  path="/profile" element={ <Suspense fallback={<Loader />}> <Profile /> </Suspense>}/>
                    <Route  path="/search/:id" element={ <Suspense fallback={<Loader />}> <Movies pagename="searchmovie"/> </Suspense>}/>
                    <Route  path="/packagelist" element={ <Suspense fallback={<Loader />}> <PackageList /> </Suspense>}/>
                    <Route  path="/about-us" element={ <Suspense fallback={<Loader />}> <ComingSoon /> </Suspense>}/>
                    <Route  path="/contact" element={ <Suspense fallback={<Loader />}> <ComingSoon /> </Suspense>}/>
                    <Route  path="/terms-of-use" element={ <Suspense fallback={<Loader />}> <ComingSoon /> </Suspense>}/>
                    <Route  path="/customer-review" element={ <Suspense fallback={<Loader />}> <ComingSoon /> </Suspense>}/>
                </Route>
                <Route path="news"  element={<Suspense fallback={<Loader />}><LayoutMovies /></Suspense>}>
                    <Route  index element={ <Suspense fallback={<Loader />}> <News  /> </Suspense>}/>
                    
                    <Route  path="news-details/:id" element={ <Suspense fallback={<Loader />}> <NewsDetails /> </Suspense>}/>
                </Route>
                <Route path="review"  element={<Suspense fallback={<Loader />}><LayoutReviews /></Suspense>}>
                    <Route index element={ <Suspense fallback={<Loader />}> <ReviewsPage /> </Suspense>}/>
                    <Route path="verify-account" element={ <Suspense fallback={<Loader />}> <VerifyAccount  /> </Suspense>}/>
                    <Route path=":id" element={ <Suspense fallback={<Loader />}> <ReviewsDetailsPage /> </Suspense>}/>
                </Route>
                <Route path="reviews"  element={<Suspense fallback={<Loader />}><LayoutReviews /></Suspense>}>
                    <Route index element={ <Suspense fallback={<Loader />}> <ReviewsPage /> </Suspense>}/>
                    <Route path="verify-account" element={ <Suspense fallback={<Loader />}> <VerifyAccount  /> </Suspense>}/>
                    <Route path=":id" element={ <Suspense fallback={<Loader />}> <ReviewsDetailsPage /> </Suspense>}/>
                </Route>
                <Route path="reviewer-profile" element={<Suspense fallback={<Loader />}><LayoutReviewProfile isType={'taletProfile'} /></Suspense>}>
                    <Route element={ <Suspense fallback={<Loader />}> <TalentProfileHolder /> </Suspense>}>
                        <Route index element={ <Suspense fallback={<Loader />}> <ReveiewsPosted userCheckMain={false}/> </Suspense>}/>
                        <Route path="create-review" element={ <Suspense fallback={<Loader />}> <PostReviews /> </Suspense>}/>
                        <Route path="update-profile" element={ <Suspense fallback={<Loader />}> <UpdatePofile /> </Suspense>}/>
                        <Route path="user-transactions" element={ <Suspense fallback={<Loader />}> <UpdateTransaction /> </Suspense>}/>
                        <Route path="view-talent-profile" element={ <Suspense fallback={<Loader />}> <TalentGalary /> </Suspense>}/>
                        <Route path="view-liked-profiles" element={ <Suspense fallback={<Loader />}> <TalentWishlistUI isWishlist={true}/> </Suspense>}/>
                        <Route path="view-wishlist" element={ <Suspense fallback={<Loader />}> <TalentWishlistUI isProdWishlist={true}/> </Suspense>}/>
                    </Route>
                </Route>
                <Route path="reviewer-profile-details/:profileid" element={<Suspense fallback={<Loader />}><LayoutReviewProfile isType={'taletUserProfile'}/></Suspense>}>
                    <Route element={ <Suspense fallback={<Loader />}> <TalentProfileHolder /> </Suspense>}>
                        <Route index element={ <Suspense fallback={<Loader />}> <ReveiewsPosted userCheckMain={true}/> </Suspense>}/>
                     </Route>
                </Route>
                <Route  path="/verify-user" element={ <Suspense fallback={<Loader />}> <Otp /> </Suspense>}/>
                <Route path="auth" element={<Suspense fallback={<Loader />}><Auth /></Suspense>}>
                    <Route index element={<Suspense fallback={<Loader />}><Signin /></Suspense>}/>
                    <Route path="login" element={<Suspense fallback={<Loader />}><Signin /></Suspense>}/>
                    <Route path="register" element={<Suspense fallback={<Loader />}><Signup /></Suspense>}/>
                    <Route path="forgot-password" element={<Suspense fallback={<Loader />}><ForgotPassword /></Suspense>}/>
                </Route>
                <Route path="movie-player/:movieid" element={ <Suspense fallback={<Loader />}> <MoviePlayer /> </Suspense>}/>
                <Route path="talent" element={<Suspense fallback={<Loader />}><TalentLayout  /></Suspense>}>
                    <Route index element={ <Suspense fallback={<Loader />}> <TalentHome diplayBanner={true} /> </Suspense>}/>
                    <Route path="talent-list" element={ <Suspense fallback={<Loader />}> <TalentHome diplayBanner={false}/> </Suspense>}/>
                    <Route path="create-talent" element={ <Suspense fallback={<Loader />}> <TalentCreate /> </Suspense>}/>
                    <Route path="talent-category" element={ <Suspense fallback={<Loader />}> <TalentCategory /> </Suspense>}/>
                </Route>
                <Route path="talent-profile" element={<Suspense fallback={<Loader />}><LayoutTalentProfile isType={'taletProfile'} /></Suspense>}>
                    <Route element={ <Suspense fallback={<Loader />}> <TalentProfileHolder /> </Suspense>}>
                        <Route index element={ <Suspense fallback={<Loader />}> <TalentProfile userCheckMain={false}/> </Suspense>}/>
                        <Route path="update-profile" element={ <Suspense fallback={<Loader />}> <UpdatePofile /> </Suspense>}/>
                        <Route path="user-transactions" element={ <Suspense fallback={<Loader />}> <UpdateTransaction /> </Suspense>}/>
                        <Route path="view-talent-profile" element={ <Suspense fallback={<Loader />}> <TalentGalary /> </Suspense>}/>
                        <Route path="view-liked-profiles" element={ <Suspense fallback={<Loader />}> <TalentWishlistUI isWishlist={true}/> </Suspense>}/>
                        <Route path="view-wishlist" element={ <Suspense fallback={<Loader />}> <TalentWishlistUI isProdWishlist={true}/> </Suspense>}/>
                    </Route>
                </Route>
                <Route path="user-talent-profile/:profileid" element={<Suspense fallback={<Loader />}><LayoutTalentProfile isType={'taletUserProfile'}/></Suspense>}>
                    <Route element={ <Suspense fallback={<Loader />}> <TalentProfileHolder /> </Suspense>}>
                        <Route index element={ <Suspense fallback={<Loader />}> <TalentProfile userCheckMain={true}/> </Suspense>}/>
                     </Route>
                </Route>
                <Route path="pages" element={<Suspense fallback={<Loader />}><Layout /></Suspense>}>
                    <Route index element={ <Suspense fallback={<Loader />}> <CmsPages /> </Suspense>}></Route>
                    <Route path=":id" element={ <Suspense fallback={<Loader />}> <CmsPages /> </Suspense>}></Route>
                </Route>
                <Route path="*" element={<Suspense fallback={<Loader />}><PageNotFound isType={'taletUserProfile'}/></Suspense>}/>
            </Routes>
        </HashRouter>
}
export default RouterComponent;
