import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAPIWapper} from '../_helpers'


const name = 'userdetails'
function createInitialState(){
    return {
        user : {},
        userMain : {},
        userTransactions : [],
        error : null,
        loader : false
    }
}
function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/v1`;
    function profile(){
        return createAsyncThunk(
            `${name}/profile`,
            async (profileData) => {
                if(profileData && profileData.profileid){
                    return await fetchAPIWapper.get(`${baseUrl}/userdetails?profileId=${profileData.profileid}&createview=${profileData.createview}`)
                }
                else{
                    return await fetchAPIWapper.get(`${baseUrl}/userdetails`)
                }
            }
        )
    }
    function usertransactions(){
        return createAsyncThunk(
            `${name}/usertransactions`,
             async (userData) => await fetchAPIWapper.get(`${baseUrl}/usertransactions`, userData, 'formData')
        )
    }
    function profiledata(){
        return createAsyncThunk(
            `${name}/profiledata`,
            async (profileData) => {
                if(profileData && profileData.profileid){
                    return await fetchAPIWapper.get(`${baseUrl}/userdetails?profileId=${profileData.profileid}&createview=${profileData.createview}`)
                }
                else{
                    return await fetchAPIWapper.get(`${baseUrl}/userdetails`)
                }
            }
        )
    }
    function updateprofile(){
        return createAsyncThunk(
            `${name}/updateprofile`,
             async (userData) => await fetchAPIWapper.post(`${baseUrl}/userdetails`, userData, 'formData')
        )
    }
    return {
        profile: profile(),
        updateprofile : updateprofile(),
        profiledata : profiledata(),
        usertransactions : usertransactions()
    }
}

function createExtraReducers(){
    function profile(){

        let { pending, fulfilled, rejected } = extraActions.profile;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [fulfilled]:(state, action)=>{
                state.user = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },

            

            [extraActions.usertransactions.pending]: (state) => {
                state.error = null;
                state.loader = true
                state.userTransactions = [];
            },
            [extraActions.usertransactions.fulfilled]:(state, action)=>{
                state.userTransactions = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.usertransactions.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false;
                state.userTransactions = [];
            },

            [extraActions.profiledata.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.profiledata.fulfilled]:(state, action)=>{
                state.userMain = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.profiledata.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },

            
            [extraActions.updateprofile.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.updateprofile.fulfilled]:(state, action)=>{
                state.user = (action.payload && action.payload.data) ? action.payload.data : {};
                state.error = null;
                state.loader = false
            },
            [extraActions.updateprofile.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },
        }
    }
    return {
        ...profile()
    }
}

const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });
export const profileActions = { ...slice.actions, ...extraActions };
export const profileReducer = slice.reducer;