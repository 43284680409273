import {createSlice} from '@reduxjs/toolkit';
const name = 'toast'
function createInitialState(){
    return {
        toastStatus : false,
        toastMessage : ''
    }
}
function createReducers(){
    function toggleToast(state, {payload}){
        state.toastStatus = payload.status
        state.toastMessage = payload.message
    }
    return {
        toggleToast
    };
}
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });
export const toastActions = { ...slice.actions };
export const toastReducer = slice.reducer;