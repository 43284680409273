import {configureStore} from '@reduxjs/toolkit'
import { authReducer } from './auth.slice';
import { profileReducer } from './profile.slice';
import { paymentReducer  } from './payment.slice'
import {movieReducer} from './movies.slice'
import {talentReducer} from './talent.slice'
import {homeReducer} from './home.slice'
import {toastReducer} from './toast.slice'
import {newsReducer} from './news.slice'
import {reviewsReducer} from './reviews.slice'
import {articleReducer} from './article.slice'
export * from './auth.slice';
export * from './profile.slice';
export * from './movies.slice';
export * from './talent.slice';
export * from './home.slice';
export * from './toast.slice';
export * from './news.slice';
export * from './reviews.slice';
export * from './article.slice';


export const store =  configureStore({
    reducer:{
        auth :authReducer,
        profile : profileReducer,
        movies: movieReducer,
        payment : paymentReducer,
        talent : talentReducer,
        home:homeReducer,
        toast:toastReducer,
        news:newsReducer,
        reviews:reviewsReducer,
        article:articleReducer
    }
})