import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAPIWapper} from '../_helpers'
const name = 'userdetails'
function createInitialState(){
    return {
        getNews : [],
        latestNews : [],
        dontMiss : [],
        recentWidgets : [],
        newsDetails : {},
        totalCount : 0,
        error : null,
        loader : false
    }
}
function createReducers(){
    function setWishlist(state, {payload}){
        state.wishlist = payload
    }
    function fileuploadProgress(state, {payload}){
        state.fileprogress = payload
    }
    return {
        setWishlist,
        fileuploadProgress
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    function getNews(){
        return createAsyncThunk(
            `${name}/getNews`,
             async (data) => await fetchAPIWapper.get(`${baseUrl}/getNews?sorttype=${data.sorttype}`)
        )
    }

    function getMoreNew(){
        return createAsyncThunk(
            `${name}/getMoreNew`,
             async (data) => await fetchAPIWapper.get(`${baseUrl}/getNews?currentCount=${data.currentCount}&sorttype=${data.sorttype}`)
        )
    }

    function latestNews(){
        return createAsyncThunk(
            `${name}/latestNews`,
             async (data) =>  await fetchAPIWapper.get(`${baseUrl}/getCategoryNews?category_id=${data.category_id}`)
        )
    }

    function dontMiss(){
        return createAsyncThunk(
            `${name}/dontMiss`,
             async (data) =>  await fetchAPIWapper.get(`${baseUrl}/getCategoryNews?category_id=${data.category_id}`)
        )
    }

    function recentWidgets(){
        return createAsyncThunk(
            `${name}/recentWidgets`,
             async (data) =>  await fetchAPIWapper.get(`${baseUrl}/getCategoryNews?category_id=${data.category_id}`)
        )
    }

    function newsDetails(){
        return createAsyncThunk(
            `${name}/newsDetails`,
             async (data) =>  await fetchAPIWapper.get(`${baseUrl}/getNewsDetails?new_slug=${data.new_slug}`)
        )
    }

    
    
    return {
        getNews: getNews(),
        getMoreNew : getMoreNew(),
        latestNews : latestNews(),
        dontMiss : dontMiss(),
        recentWidgets : recentWidgets(),
        newsDetails : newsDetails()
    }
}

function createExtraReducers(){
    function newcategories(){
        let { pending, fulfilled, rejected } = extraActions.getNews;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loader = true
                state.getNews = [];
                state.totalCount = 0;
            },
            [fulfilled]:(state, action)=>{
                state.totalCount = (action.payload && action.payload.totalcount) ? action.payload.totalcount : 0;
                state.getNews = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [rejected]:(state, action) =>{
                state.getNews = [];
                state.totalCount = 0;
                state.error = action.error;
                state.loader = false
            },

            

            [extraActions.getMoreNew.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.getMoreNew.fulfilled]:(state, action)=>{
                let getNews = (action.payload && action.payload.data) ? action.payload.data : [];
                state.error = null;
                state.getNews = [...state.getNews, ...getNews]
                state.loader = false
            },
            [extraActions.getMoreNew.rejected]:(state, action) =>{
                state.error = action.error;
                state.latestNews = [];
                state.loader = false
            },


            [extraActions.latestNews.pending]: (state) => {
                state.error = null;
                state.latestNews = [];
                state.loader = true
            },
            [extraActions.latestNews.fulfilled]:(state, action)=>{
                state.error = null;
                state.latestNews = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.latestNews.rejected]:(state, action) =>{
                state.error = action.error;
                state.latestNews = [];
                state.loader = false
            },

            [extraActions.dontMiss.pending]: (state) => {
                state.error = null;
                state.dontMiss = [];
                state.loader = true;
            },
            [extraActions.dontMiss.fulfilled]:(state, action)=>{
                state.error = null;
                state.dontMiss = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.dontMiss.rejected]:(state, action) =>{
                state.error = action.error;
                state.dontMiss = [];
                state.loader = false
            },

            [extraActions.recentWidgets.pending]: (state) => {
                state.error = null;
                state.loader = true
                state.recentWidgets = []
                
            },
            [extraActions.recentWidgets.fulfilled]:(state, action)=>{
                state.error = null;
                state.recentWidgets = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.recentWidgets.rejected]:(state, action) =>{
                state.error = action.error;
                state.recentWidgets = [];
                state.loader = false
            },
            
            [extraActions.newsDetails.pending]: (state) => {
                state.error = null;
                state.newsDetails = {};
                state.loader = true
              
            },
            [extraActions.newsDetails.fulfilled]:(state, action)=>{
                state.error = null;
                state.totalCount = (action.payload && action.payload.totalCount) ? action.payload.totalCount : {};
                state.newsDetails = (action.payload && action.payload.data && action.payload.data.length>0) ? action.payload.data[0] : {};
                state.loader = false
            },
            [extraActions.newsDetails.rejected]:(state, action) =>{
                state.error = action.error;
                state.newsDetails = {};
                state.loader = false
            }, 

            
        }
    }
    return {
        ...newcategories()
    }
}

const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers,extraReducers  });
export const newsActions = { ...slice.actions, ...extraActions };
export const newsReducer = slice.reducer;