import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAPIWapper} from '../_helpers'
const name = 'paymentdetails'
function createInitialState(){
    return {
        paymentDetails : {},
        orderPaymentDetails : {},
        paymentSubscriptionVideo : [],
        paymentValidation : {},
        paymentMovieAccess : {},
        paymentSubscription : [],        
        paymentValidationMessage : '',
        paymentMovieAccessMessage : '',
        paymentPackageValidationMessage : '',
        error : null,
        loader : false,
        paymentPopupLoader : false
    }
}



function createReducers(){
    function removePaymentDetals(state, {payload}){
        state.paymentDetails =  {}
        state.paymentValidation = {}
        state.paymentValidationMessage = '';
        state.loader = false
        state.paymentPopupLoader = false;
    }
    return {
        removePaymentDetals,
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    function validateOrder(){
        return createAsyncThunk(
            `${name}/validateOrder`,
             async ({movieID}) => await fetchAPIWapper.post(`${baseUrl}/v1/validate-order`, {movieID})
        )
    }
    function validateMovieAccess(){
        return createAsyncThunk(
            `${name}/validateMovieAccess`,
             async ({movieID}) => await fetchAPIWapper.post(`${baseUrl}/v1/movie-access`, {movie_id:movieID})
        )
    }
    function validatePacakgeOrder(){
        return createAsyncThunk(
            `${name}/validatePacakgeOrder`,
             async ({packageID}) => await fetchAPIWapper.post(`${baseUrl}/v1/validate-order-package`, {packageID})
        )
    }
    function validatePayment(){
        return createAsyncThunk(
            `${name}/validatePayment`,
             async ({signature, payment_id, order_id}) => await fetchAPIWapper.post(`${baseUrl}/v1/validate-payment`, {signature, payment_id, order_id})
        )
    }
    function validatePaymentPackage(){
        return createAsyncThunk(
            `${name}/validatePaymentPackage`,
             async ({signature, payment_id, order_id}) => await fetchAPIWapper.post(`${baseUrl}/v1/validate-payment-package`, {signature, payment_id, order_id})
        )
    }
    function getSubscription(){
        return createAsyncThunk(
            `${name}/validateSubscription`,
             async () => await fetchAPIWapper.get(`${baseUrl}/v1/validate-subscription`)
        )
    }
    return {
        validateOrder : validateOrder(),
        validatePayment: validatePayment(),
        validatePaymentPackage: validatePaymentPackage(),
        validateSubscription : getSubscription(),
        validateOrderPackage : validatePacakgeOrder(),
        validateMovieAccess : validateMovieAccess()
    }
}

function createExtraReducers(){
    function paymentdetails(){
        let { pending, fulfilled, rejected } = extraActions.validateOrder;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loader = true
                state.paymentPopupLoader = true;
            },
            [fulfilled]:(state, action)=>{
                state.paymentDetails =  (action.payload && action.payload.data) ? action.payload.data : '';
                state.paymentValidation = {}
                state.paymentValidationMessage = '';
                state.loader = false
                state.paymentPopupLoader = false;
            },
            [rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
                state.paymentPopupLoader = false;
            },

            [extraActions.validateMovieAccess.pending]: (state) => {
                state.paymentMovieAccess = {};
                state.paymentMovieAccessMessage = '';
                state.error = null;
                state.loader = true
            },
            [extraActions.validateMovieAccess.fulfilled]:(state, action)=>{
                console.log('action, action', action.payload)
                state.paymentMovieAccess =  (action.payload && action.payload.data) ? action.payload.data : '';
                state.paymentMovieAccessMessage = (action.payload && action.payload.message) ? action.payload.message : '';
                state.loader = false
            },
            [extraActions.validateMovieAccess.rejected]:(state, action) =>{
                state.paymentMovieAccessMessage = '';
                state.paymentMovieAccess = [];
                state.error = action.error;
                state.loader = false
            },


            [extraActions.validateOrderPackage.pending]: (state) => {
                state.paymentPackageValidationMessage = '';
                state.error = null;
                state.loader = true
            },
            [extraActions.validateOrderPackage.fulfilled]:(state, action)=>{
                state.orderPaymentDetails =  (action.payload && action.payload.data) ? action.payload.data : '';
                state.paymentPackageValidationMessage = (action.payload && action.payload.message) ? action.payload.message : '';
                state.loader = false
            },
            [extraActions.validateOrderPackage.rejected]:(state, action) =>{
                state.paymentPackageValidationMessage = '';
                state.error = action.error;
                state.loader = false
            },
            
            [extraActions.validatePayment.pending]: (state) => {
                state.paymentValidationMessage = '';
                state.error = null;
                state.loader = true
                state.paymentPopupLoader = true;
            },
            [extraActions.validatePayment.fulfilled]:(state, action)=>{
                state.paymentValidationMessage = (action.payload && action.payload.message) ? action.payload.message : '';
                state.loader = false
                state.paymentPopupLoader = false;
            },
            [extraActions.validatePayment.rejected]:(state, action) =>{
                state.paymentValidationMessage = '';
                state.error = action.error;
                state.loader = false
                state.paymentPopupLoader = false;
            },

            [extraActions.validatePaymentPackage.pending]: (state) => {
                state.paymentPackageValidationMessage = '';
                state.error = null;
                state.loader = true
                state.paymentPopupLoader = true;
            },
            [extraActions.validatePaymentPackage.fulfilled]:(state, action)=>{
                state.paymentPackageValidationMessage = (action.payload && action.payload.message) ? action.payload.message : '';
                state.loader = false
                state.paymentPopupLoader = false;
            },
            [extraActions.validatePaymentPackage.rejected]:(state, action) =>{
                state.paymentPackageValidationMessage = '';
                state.error = action.error;
                state.loader = false
                state.paymentPopupLoader = false;
            },


            [extraActions.validateSubscription.pending]: (state) => {
                state.paymentSubscription = [];
                state.paymentSubscriptionVideo  = [];
                state.error = null;
                state.loader = true
            },
            [extraActions.validateSubscription.fulfilled]:(state, action)=>{
                state.paymentSubscription = (action.payload && action.payload.data) ? action.payload.data : '';
                state.paymentSubscriptionVideo = (action.payload && action.payload.moveieData) ? action.payload.moveieData : '';
                state.loader = false
            },
            [extraActions.validateSubscription.rejected]:(state, action) =>{
                state.paymentSubscription = [];
                state.paymentSubscriptionVideo  = [];
                state.error = action.error;
                state.loader = false
            }
        }
    }
    return {
        ...paymentdetails()
    }
}

const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
export const paymentActions = { ...slice.actions, ...extraActions };
export const paymentReducer = slice.reducer;