import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAPIWapper} from '../_helpers'

// Creating Slice for Authendication
const name = 'auth'

function createInitialState(){
    return {
        token : localStorage.getItem('token'),
        message : '',
        error : null,
        checkPopup : false,
        loader : false
    }
}

function createReducers(){
    function logout(state){
        state.token = null;
        state.loader = false
        state.unAuthMessage = '';
        state.checkPopup = false
        localStorage.removeItem('token');
        window.location.href = '/#/auth/login'
        window.location.reload();
    }
    function logoutAuth(state){
        state.token = null;
        state.loader = false
        state.unAuthMessage = '';
        state.checkPopup = false
        localStorage.removeItem('token');
    }
    function toggleAuthPopup(state, {payload}){
        state.checkPopup = payload
    }
    function updateAuthMessage(state, {payload}){
        state.unAuthMessage = payload;
    }
    return {
        logout,
        toggleAuthPopup,
        updateAuthMessage,
        logoutAuth
    };
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/auth`;
    function login(){
        return createAsyncThunk(
            `${name}/login`,
            async ({email, password}) => await fetchAPIWapper.post(`${baseUrl}/login`, { email, password })
        )
    }
    function register(){
        return createAsyncThunk(
            `${name}/login`,
             async (registerData) => await fetchAPIWapper.post(`${baseUrl}/register`, registerData)
        )
    }
    function forgotpassword(){
        return createAsyncThunk(
            `${name}/forgotpassword`,
             async (sendData) => await fetchAPIWapper.post(`${baseUrl}/forgot-password`, sendData)
        )
    }
    function updatepassword(){
        return createAsyncThunk(
            `${name}/updatepassword`,
             async (sendData) => await fetchAPIWapper.post(`${baseUrl}/verify-password`, sendData)
        )
    }
    return {
        login: login(),
        register: register(),
        forgotpassword : forgotpassword(),
        updatepassword : updatepassword()
    }
}

function createExtraReducers(){
    function login(){
        let { pending, fulfilled, rejected } = extraActions.login;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loader = true
                state.checkPopup = false
            },
            [fulfilled]:(state, action)=>{
                localStorage.setItem('token', action.payload.token);
                state.token = action.payload.token;
                state.loader = false
                state.checkPopup = false
            },
            [rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
                state.checkPopup = false
            },

            [extraActions.forgotpassword.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.forgotpassword.fulfilled]:(state, action)=>{
                state.message = (action.payload && action.payload.message) ? action.payload.message : '';
                state.loader = false
            },
            [extraActions.forgotpassword.rejected]:(state) =>{
                state.message = '';
                state.loader = false
            },


            [extraActions.updatepassword.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.updatepassword.fulfilled]:(state, action)=>{
                state.message = (action.payload && action.payload.message) ? action.payload.message : '';
                state.loader = false
            },
            [extraActions.updatepassword.rejected]:(state) =>{
                state.message = '';
                state.loader = false
            },


        }
    }
    return {
        ...login()
    }
}

const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;