import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAPIWapper} from '../_helpers'
const name = 'homepage'
function createInitialState(){
    return {
        topmenu : [],
        selectedtalent : [],
        packageList : [],
        selectedpackageList : [],
        sendOTP : {},
        veifyOTP : {},
        error : null,
        loader : false,
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    function homeMainMenu(){
        return createAsyncThunk(
            `${name}/homemainmenu`,
             async () => await fetchAPIWapper.get(`${baseUrl}/homemainmenu`)
        )
    }

    function selectedTalent(){
        return createAsyncThunk(
            `${name}/selectedtalent`,
             async () => await fetchAPIWapper.get(`${baseUrl}/selectedtalent`)
        )
    }

    function packageList(){
        return createAsyncThunk(
            `${name}/packagelist`,
             async () => await fetchAPIWapper.get(`${baseUrl}/v1/packagelist`)
        )
    }

    function updatePackageList(){
        return createAsyncThunk(
            `${name}/updatepackagelist`,
             async (data) => await fetchAPIWapper.post(`${baseUrl}/v1/updatepackage`, data)
        )
    }

    function sendOTP(){
        return createAsyncThunk(
            `${name}/sendotp`,
             async (data) => await fetchAPIWapper.post(`${baseUrl}/v1/send-otp`, data)
        )
    }

    function verifyOTP(){
        return createAsyncThunk(
            `${name}/verifyotp`,
             async (data) => await fetchAPIWapper.post(`${baseUrl}/v1/verify-otp`, data)
        )
    }

    
    
    return {
        homemainmenu: homeMainMenu(),
        selectedtalent: selectedTalent(),
        packagelist : packageList(),
        updatepackagelist : updatePackageList(),
        sendotp: sendOTP(),
        verifyotp: verifyOTP() 
    }
}

function createReducers(){
    function clearError(state, {payload}){
        if(payload){
            state.error = null
        }
        else{
            state.error = null
        }
    }
    function toggleLoader(state,{payload}){
        state.loader = payload
    }
    return {
        clearError,
        toggleLoader
    }
}

function createExtraReducers(){
    function homelist(){
        let { pending, fulfilled, rejected } = extraActions.homemainmenu;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loader = true
                state.topmenu = [];
            },
            [fulfilled]:(state, action)=>{
                state.topmenu = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },

            [extraActions.selectedtalent.pending]: (state) => {
                state.error = null;
                state.loader = true
                state.selectedtalent = [];
            },
            [extraActions.selectedtalent.fulfilled]:(state, action)=>{
                state.selectedtalent = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [extraActions.selectedtalent.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
                state.selectedtalent = [];
            },

            [extraActions.packagelist.pending]: (state) => {
                state.error = null;
                state.loader = true
                state.packageList = [];
            },
            [extraActions.packagelist.fulfilled]:(state, action)=>{
                state.packageList = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [extraActions.packagelist.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
                state.packageList = [];
            },

            [extraActions.updatepackagelist.pending]: (state) => {
                state.error = null;
                state.loader = true
                state.selectedpackageList = [];
            },
            [extraActions.updatepackagelist.fulfilled]:(state, action)=>{
                state.selectedpackageList = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [extraActions.updatepackagelist.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
                state.selectedpackageList = [];
            },


            [extraActions.sendotp.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.sendotp.fulfilled]:(state, action)=>{
                state.sendOTP = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [extraActions.sendotp.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },
            

            [extraActions.verifyotp.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.verifyotp.fulfilled]:(state, action)=>{
                state.veifyOTP = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [extraActions.verifyotp.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            }
        }
    }
    return {
        ...homelist()
    }
}

const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
export const homeActions = { ...slice.actions, ...extraActions };
export const homeReducer = slice.reducer;