import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAPIWapper} from '../_helpers'
const name = 'userreviews'
function createInitialState(){
    return {
        moveNameList : [],
        latestNews : [],
        dontMiss : [],
        reviewList : [],
        userReviewList : [],
        imageUrl : '',
        recentWidgets : [],
        reviewDetails : {},
        reviewDetailsList : [],
        selectedReview : {},
        topReviwes : [],
        totalCount : 0,
        error : null,
        loader : false,
        message : ''
    }
}
function createReducers(){
    function setSelectedReview(state, {payload}){
        state.selectedReview = payload
    }
    function fileuploadProgress(state, {payload}){
        state.fileprogress = payload
    }
    return {
        setSelectedReview,
        fileuploadProgress
    }
}

function createExtraActions() {
    const baseUrlNoAuth = `${process.env.REACT_APP_API_URL}`;
    const baseUrl = `${process.env.REACT_APP_API_URL}/v1`;
    function moveNameList(){
        return createAsyncThunk(
            `${name}/moveNameList`,
             async () => await fetchAPIWapper.get(`${baseUrl}/movename`)
        )
    }

    function sendInterest(){
        return createAsyncThunk(
            `${name}/sendInterest`,
             async (data) => await fetchAPIWapper.post(`${baseUrl}/send-interest`, data)
        )
    }

    

    function userReviewList(){
        return createAsyncThunk(
            `${name}/userReviewList`,
            async (data) => {
                if(data && data.profileId){
                    let searchPrams = new URLSearchParams(data).toString();
                    return await fetchAPIWapper.get(`${baseUrl}/userReviewList?${searchPrams}`)
                }
                else{
                    return await fetchAPIWapper.get(`${baseUrl}/userReviewList`)
                }
            }
        )
    }
    function reviewList(){
        return createAsyncThunk(
            `${name}/reviewList`,
             async () => await fetchAPIWapper.get(`${baseUrlNoAuth}/reviewList`)
        )
    }
    function topReviwes(){
        return createAsyncThunk(
            `${name}/topReviwes`,
             async () => await fetchAPIWapper.get(`${baseUrlNoAuth}/top-reviwes`)
        )
    }

    

    function reviewListMoreNew(){
        return createAsyncThunk(
            `${name}/reviewListMoreNew`,
             async (data) => await fetchAPIWapper.get(`${baseUrlNoAuth}/reviewList?currentCount=${data.currentCount}`)
        )
    }

    function latestNews(){
        return createAsyncThunk(
            `${name}/latestNews`,
             async (data) =>  await fetchAPIWapper.get(`${baseUrl}/getCategoryNews?category_id=${data.category_id}`)
        )
    }

    function dontMiss(){
        return createAsyncThunk(
            `${name}/dontMiss`,
             async (data) =>  await fetchAPIWapper.get(`${baseUrl}/getCategoryNews?category_id=${data.category_id}`)
        )
    }

    function recentWidgets(){
        return createAsyncThunk(
            `${name}/recentWidgets`,
             async (data) =>  await fetchAPIWapper.get(`${baseUrl}/getCategoryNews?category_id=${data.category_id}`)
        )
    }

    function reviewDetails(){
        return createAsyncThunk(
            `${name}/reviewDetails`,
             async (data) =>  await fetchAPIWapper.get(`${baseUrlNoAuth}/reviewDetails?movieID=${data.id}`)
        )
    }

    function verifyProfile(){
        return createAsyncThunk(
            `${name}/verifyProfile`,
             async (userData) => await fetchAPIWapper.post(`${baseUrl}/verifyReviewProfile`, userData, 'formData')
        )
    }

    function postReviews(){
        return createAsyncThunk(
            `${name}/postReviews`,
             async (userData) => await fetchAPIWapper.post(`${baseUrl}/createReview`, userData, 'formData')
        )
    }

    function removeReview(){
        return createAsyncThunk(
            `${name}/removeReview`,
             async (data) =>  await fetchAPIWapper.get(`${baseUrlNoAuth}/remove-review?reviewID=${data.id}`)
        )
    }

    

    function userReviewOpinion(){
        return createAsyncThunk(
            `${name}/userReviewOpinion`,
            async (data) => {
                if(data && data.review_id){
                    let searchPrams = new URLSearchParams(data).toString();
                    return await fetchAPIWapper.get(`${baseUrl}/userReviewOpinion?${searchPrams}`)
                }
            }
        )
    }


    

    
    
    return {
        verifyProfile : verifyProfile(),
        reviewList : reviewList(),
        topReviwes : topReviwes(),
        userReviewList : userReviewList(),
        moveNameList: moveNameList(),
        reviewListMoreNew : reviewListMoreNew(),
        latestNews : latestNews(),
        dontMiss : dontMiss(),
        recentWidgets : recentWidgets(),
        reviewDetails : reviewDetails(),
        postReviews: postReviews(),
        userReviewOpinion:userReviewOpinion(),
        removeReview : removeReview(),
        sendInterest : sendInterest()
    }
}

function createExtraReducers(){
    function newcategories(){
        let { pending, fulfilled, rejected } = extraActions.moveNameList;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loader = true
                state.moveNameList = [];
            },
            [fulfilled]:(state, action)=>{
                state.totalCount = (action.payload && action.payload.totalcount) ? action.payload.totalcount : 0;
                state.moveNameList = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },

            

            [extraActions.userReviewOpinion.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.userReviewOpinion.fulfilled]:(state, action)=>{
                state.error = null;
                state.loader = false
            },
            [extraActions.userReviewOpinion.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },

            [extraActions.sendInterest.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.sendInterest.fulfilled]:(state, action)=>{
                state.error = null;
                state.loader = false
            },
            [extraActions.sendInterest.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },

            

            [extraActions.removeReview.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.removeReview.fulfilled]:(state, action)=>{
                state.error = null;
                state.loader = false
            },
            [extraActions.removeReview.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },

            [extraActions.reviewList.pending]: (state) => {
                state.error = null;
                state.loader = true
                state.reviewList = [];
                state.totalCount = 0;
            },
            [extraActions.reviewList.fulfilled]:(state, action)=>{
                state.totalCount = (action.payload && action.payload.totalcount) ? action.payload.totalcount : 0;
                state.reviewList = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
            },
            [extraActions.reviewList.rejected]:(state, action) =>{
                state.reviewList = [];
                state.totalCount = 0;
                state.error = action.error;
                state.loader = false
            },
            

            [extraActions.userReviewList.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.userReviewList.fulfilled]:(state, action)=>{
                state.error = null;
                state.userReviewList = (action.payload && action.payload.data) ? action.payload.data : [];
                state.loader = false
                state.imageUrl =  (action.payload && action.payload.image_url) ? action.payload.image_url : '';
            },
            [extraActions.userReviewList.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },


            [extraActions.postReviews.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.postReviews.fulfilled]:(state, action)=>{
                state.error = null;
                state.loader = false
            },
            [extraActions.postReviews.rejected]:(state, action) =>{
                state.error = action.error;
                state.loader = false
            },

            [extraActions.reviewListMoreNew.pending]: (state) => {
                state.error = null;
                state.loader = true
            },
            [extraActions.reviewListMoreNew.fulfilled]:(state, action)=>{
                let reviewList = (action.payload && action.payload.data) ? action.payload.data : [];
                state.error = null;
                state.reviewList = [...state.reviewList, ...reviewList]
                state.loader = false
            },
            [extraActions.reviewListMoreNew.rejected]:(state, action) =>{
                state.error = action.error;
                state.latestNews = [];
                state.loader = false
            },


            [extraActions.latestNews.pending]: (state) => {
                state.error = null;
                state.latestNews = [];
                state.loader = true
            },
            [extraActions.latestNews.fulfilled]:(state, action)=>{
                state.error = null;
                state.latestNews = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.latestNews.rejected]:(state, action) =>{
                state.error = action.error;
                state.latestNews = [];
                state.loader = false
            },

            [extraActions.topReviwes.pending]: (state) => {
                state.error = null;
                state.topReviwes = [];
                state.loader = true
            },
            [extraActions.topReviwes.fulfilled]:(state, action)=>{
                state.error = null;
                state.topReviwes = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.topReviwes.rejected]:(state, action) =>{
                state.error = action.error;
                state.topReviwes = [];
                state.loader = false
            },

            

            [extraActions.dontMiss.pending]: (state) => {
                state.error = null;
                state.dontMiss = [];
                state.loader = true;
            },
            [extraActions.dontMiss.fulfilled]:(state, action)=>{
                state.error = null;
                state.dontMiss = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.dontMiss.rejected]:(state, action) =>{
                state.error = action.error;
                state.dontMiss = [];
                state.loader = false
            },

            [extraActions.recentWidgets.pending]: (state) => {
                state.error = null;
                state.loader = true
                state.recentWidgets = []
                
            },
            [extraActions.recentWidgets.fulfilled]:(state, action)=>{
                state.error = null;
                state.recentWidgets = (action.payload && action.payload.data) ? action.payload.data : {};
                state.loader = false
            },
            [extraActions.recentWidgets.rejected]:(state, action) =>{
                state.error = action.error;
                state.recentWidgets = [];
                state.loader = false
            },
            
            [extraActions.reviewDetails.pending]: (state) => {
                state.error = null;
                state.reviewDetails = {};
                state.loader = true
              
            },
            [extraActions.reviewDetails.fulfilled]:(state, action)=>{
                state.error = null;
                state.totalCount = (action.payload && action.payload.totalCount) ? action.payload.totalCount : {};
                state.reviewDetails = (action.payload && action.payload.data && action.payload.data.length>0) ? action.payload.data[0] : {};
                state.reviewDetailsList = (state.reviewDetails.reviews) ? state.reviewDetails.reviews : [];
                state.selectedReview = ( state.reviewDetailsList) ?  state.reviewDetailsList[0] : {}
                state.loader = false
            },
            [extraActions.reviewDetails.rejected]:(state, action) =>{
                state.error = action.error;
                state.reviewDetails = {};
                state.loader = false
            }, 
            

            [extraActions.verifyProfile.pending]: (state) => {
                state.error = null;
                state.message = '';
                state.loader = true
              
            },
            [extraActions.verifyProfile.fulfilled]:(state, action)=>{
                state.error = null;
                state.message = (action.payload && action.payload.message) ? action.payload.message : '';
                state.loader = false
            },
            [extraActions.verifyProfile.rejected]:(state, action) =>{
                state.error = action.error;
                state.message = '';
                state.loader = false
            }, 

            

            
        }
    }
    return {
        ...newcategories()
    }
}

const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers,extraReducers  });
export const reviewsActions = { ...slice.actions, ...extraActions };
export const reviewsReducer = slice.reducer;